// import couponInfo from "../test/testData";
import store from "../../store"
import { copyData } from "./copy";
import { postDC } from "../utils/request"
export default async function (couponInfo, businessType, pres, isConvertCouponByPromoteCode) {
    // console.log(couponInfo)
    let pre = null;
    let discountPre = null;
    if (pres && pres.pres) {
        pres.pres.forEach(val => {
            if (businessType == 2) {
                if (val.childType == 6 && val.type == 4) {
                    pre = val
                }
            } else {
                if (
                    val.childType == 0 &&
                    val.type == 3
                ) {
                    val.items.sort((a, b) => {
                        return a.needConsumeMoney - b.needConsumeMoney;
                    });
                    pre = copyData(val.items);
                }
            }
            if (val.childType == 7 && val.type == 4) {
                let prePrice = {};
                let prePriceArr = []
                val.items.forEach(item => {
                    prePriceArr.push(item.pid)
                    prePrice[item.pid] = {
                        price: item.discountedPrice,
                        productDisplayPrice: item.productDisplayPrice
                    }
                })
                val.prePrice = prePrice
                val.prePriceArr = prePriceArr
                discountPre = val
            }
        });
    }
    let data = couponInfo
    // 没有门槛
    if (!data.voucherCalType) {
        return {
            usage: true,
            type: 0
        }; //没有门槛
    }
    // 金额门槛
    if (data.voucherCalType == 1) {
        let price = getPrice(data.canDaoPriceType, data.includedBoxedMealFee, pre, discountPre, businessType)
        if (price < data.minUsage / 100) {
            return {
                usage: false,
                type: 1
            };// console.log("不满足金额门槛")
        }
        return {
            usage: true,
            type: 1
        };// console.log("满足条件")
    }
    // 餐品门槛
    if (data.voucherCalType == 2) {
        //指定餐品门槛
        let P = data.minUsageProductCode ? data.minUsageProductCode : [];
        let proType = data.canDaoProductType
        let minUsageType = 1;//1 指定餐品 2 例外餐品
        // 若有值则为例外餐品门槛 修改参数信息
        if ((data.pointsoftExclProducts && data.pointsoftExclProducts.length) || data.canDaoExclProductType) {
            P = data.pointsoftExclProducts ? data.pointsoftExclProducts : [];
            proType = data.canDaoExclProductType;
            minUsageType = 2
        }
        let checkPointProRes = await checkPointPro(P, proType, minUsageType, isConvertCouponByPromoteCode);
        if (!checkPointProRes.usage) return checkPointProRes;
        if (couponInfo.voucherType == 4) {
            let priceRes = getPrice(data.canDaoPriceType, data.includedBoxedMealFee, pre, discountPre, businessType);
            if (priceRes < data.minUsage / 100) {
                return {
                    usage: false,
                    type: 1
                };// console.log("不满足金额门槛")
            }
            return {
                usage: true,
                type: 1
            };// console.log("满足条件")
        } else {
            return checkPointProRes
        }
    }
}
// 获取餐品金额
function getPrice(priceType, includedFee, pre, discountPre, businessType) {
    let total = 0;
    // 深拷贝购物车 防止影响原购物车
    let cart = copyData(store.state.cart);
    for (var i = 0; i < cart.length; i++) {
        // 如果是优惠券餐品跳过本次循环
        if (cart[i].isCouponPro) continue;
        let proPrice = cart[i].price;
        if (discountPre) {
            if (discountPre.prePriceArr.indexOf(cart[i].uid) > -1) {
                if (priceType == 0) {
                    proPrice = discountPre.prePrice[cart[i].uid].productDisplayPrice + discountPre.prePrice[cart[i].uid].price
                } else {
                    continue
                }
            }
        }

        if (cart[i].selectedFood && cart[i].selectedFood.groups) {
            let selectedFood = cart[i].selectedFood.groups
            for (var a = 0; a < selectedFood.length; a++) {
                let mains = selectedFood[a].mains
                for (var b = 0; b < mains.length; b++) {
                    let mainsGroups = mains[b].groups;
                    for (var c = 0; c < mainsGroups.length; c++) {
                        let products = mainsGroups[c].products
                        for (var d = 0; d < products.length; d++
                        ) {
                            proPrice += products[d].price * products[d].num;
                        }
                    }
                }
            }
        }
        proPrice *= cart[i].num;
        total += proPrice;
    }
    if (priceType == 0) {
        if (businessType == 2 && pre) {
            let value = 1 - pre.value / 100;
            let M = Math.round((total * value + Number.EPSILON) * 10) / 10;
            total = total - M;
        }
        if (businessType != 2 && pre) {
            pre.forEach(val => {
                if (total >= val.needConsumeMoney) {
                    total += val.price;
                    return
                }
            })
        }
    }
    if (includedFee) {
        total += 1
    }
    return total
}
// 指定餐品门槛
async function checkPointPro(proCode, type, minUsageType, isConvertCouponByPromoteCode) {
    // type 0全部餐品 1单品 2套餐
    let cart = copyData(store.state.cart);
    var hasPointPro = false;//购物车中是否包含某个或某些餐品
    if (proCode.length > 0) {
        // 判断是否包含指定餐品或例外餐品
        // 根据餐品映射码获取餐品id
        let params = {
            actionName: "candao.product.getProductMapping",
            content: {
                posId: 396,
                brandId:41
            },
        };
        let H5Seting = store.state.H5Seting;
        if (H5Seting) {
            let brandInfos = H5Seting.brandInfos;
            if (brandInfos && brandInfos.length) {
                params.content.brandId = brandInfos[0].brandId
            }
        }
        let mappingIdArr = [];
        if (isConvertCouponByPromoteCode) {
            mappingIdArr = proCode
        } else {
            for (let j = 0; j < proCode.length; j++) {
                params.content.mappingCode = proCode[j];
                let res = await postDC("Action", params);
                if (res.status == 1 && res.data) {
                    mappingIdArr.push(res.data.mappingId);
                }
            }
        }

        // 判断购物车中是否包含指定餐品
        for (var i = 0; i < cart.length; i++) {
            if (cart[i].isCouponPro) continue;
            if (mappingIdArr.indexOf(cart[i].uid) > -1) {
                hasPointPro = true;
                break
            }
        }
        if (mappingIdArr.length != proCode.length) {
            return {
                usage: false,
                type: 2,
            };//有接口失败 默认优惠券不可用
        }
    } else {
        hasPointPro = true
    }


    // 判断是否包含 指定餐品或例外 类型
    let proType = type
    let hasPointProType = proType == 0 ? true : false;//购物车中是否有对应类型餐品
    if (proType) {
        for (let i = 0; i < cart.length; i++) {
            if (cart[i].isCouponPro) continue
            if (proType == cart[i].type) {
                hasPointProType = true;
                break
            }
        }
    }

    // 则购物车至少满足一个条件：含有≥1个指定餐品或含有≥1个指定餐品分类 此时满足条件
    if (proCode.length > 0 && !type) {
        return {
            usage: minUsageType == 1 ? hasPointPro : !hasPointPro,
            type: 2
        }
    }
    if (!proCode.length && type) {
        return {
            usage: minUsageType == 1 ? hasPointProType : !hasPointProType,
            type: 2
        }
    }
    if (minUsageType == 1) {
        return {
            usage: minUsageType == 1 ? hasPointPro || hasPointProType : true && false,
            type: 2
        }
    } else if (proCode.length && type) {
        return {
            usage: minUsageType == 1 ? hasPointPro && hasPointProType : !hasPointPro && !hasPointProType,
            type: 2
        }
    }
    return {
        usage: false,
        type: 2
    }

    // // 指定餐品类型门槛
    // if (minUsageType == 1) {
    //     // 则购物车至少满足一个条件：含有≥1个指定餐品或含有≥1个指定餐品分类 此时满足条件
    //     if (proCode.length > 0 && !type) {
    //         if (hasPointPro) return true;//console.log("指定餐品类型 满足餐品门槛");
    //     }
    //     if (!proCode.length && type) {
    //         if (hasPointProType) return true;//console.log("指定餐品类型 满足餐品类型门槛");
    //     }
    //     if (proCode.length && type) {
    //         if (hasPointPro && hasPointProType) {
    //             return true;//console.log("指定餐品类型 满足餐品与餐品类型双门槛")
    //         }
    //     }
    //     return false;//console.log("指定餐品类型 不满足门槛")
    // }
    // // 以下为例外餐品类型门槛
    // if (proCode.length > 0 && !type) {
    //     if (!hasPointPro) return true;// console.log("例外餐品类型门槛 满足餐品门槛")
    // }
    // if (!proCode.length && type) {
    //     if (!hasPointProType) return true;// console.log("例外餐品类型门槛 满足餐品类型门槛")
    // }
    // if (proCode.length > 0 && type) {
    //     if (!hasPointPro || !hasPointProType) return true;// console.log("例外餐品类型门槛 满足餐品与餐品类型门槛")
    // }
    // return false;// console.log("例外餐品类型门槛 不满足门槛")
}
