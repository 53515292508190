<template>
  <div class="couponBox">
    <div class="coupon">
      <div class="title">
        <p class="closeIcon" @click="closeCoupon"></p>
        <p>
          {{ useCoupon }}
        </p>
        <p></p>
      </div>
      <div class="couponList" v-if="couponList && couponList.length">
        <div
          class="itemBox"
          v-for="(item, index) in couponList"
          :key="item.voucherId"
        >
          <coupon-list
            @selectCoupon="selectCoupon(index, item)"
            :hide="true"
            :couponInfo="item"
            :page="1"
          ></coupon-list>
          <!-- <p
            class="selectIcon"
            @click="selectCoupon(index, item)"
            :class="{ active: couponIndex == index }"
          ></p> -->
        </div>
        <div class="btnBox">
          <p v-if="false" class="finishBtn" @click="finishSelect">
            {{ apply }}
          </p>
        </div>
      </div>
      <div class="couponList" v-else>
        <img class="noneData" src="@/assets/icon/nonedata.png" alt="" />
        <p class="noneC">{{ noneCoupon }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import CouponList from "../coupon/CouponList.vue";
import language from "../../assets/js/language.js";

export default {
  name: "UseCoupon",
  data() {
    return {
      lanType: this.$store.state.language,
      // 静态文字
      apply: "",
      useCoupon: "",
      quXiao: "",
      SYMK: "",
      TDCP: "",
      BZCD: "",
      BZSYSJFW: "",
      HQXXSB: "",
      selected: false, //是否选中优惠券
      couponIndex: null,
      couponInfo: null, //选择的优惠券信息
      noneCoupon:
        this.$store.state.language == 1
          ? "暫時未有優惠券"
          : "No Available Coupons",
      GWCWKYHCW: "",
    };
  },
  props: {
    couponList: {
      type: Array,
      default() {
        return [];
      },
    },
    productMenu: {
      type: Object,
      default() {
        return {};
      },
    },
    totalPricePre: {
      type: Number,
      default: 0,
    },
    storePres: {
      default: {},
    },
    businessType: {
      default: 1,
    },
    isCheck: {
      default: true,
    },
  },
  methods: {
    closeCoupon() {
      this.$emit("closeCoupon");
    },
    // 选择优惠券
    async selectCoupon(index, info) {
      this.$parent.isChangePage = false;
      if(!this.isCheck){
        if(this.$store.state.couponInfo){
          this.$parent.shouldRemoveVoucher(1);
          return;
        }
      }
      this.couponIndex = index;
      this.couponInfo = info;
      this.$store.commit("saveShowCoupon", false);
      if (!this.isCheck) {
        info.isCanUserVoucher = false;
        this.$parent.showCoupon = false;
        this.$emit("finishSelect", this.couponInfo);
      } else {
        this.$parent.isVoucherByCode = false;
        this.$emit("checkSelectCoupon", this.couponInfo, true, true);
      }
    },
    finishSelect() {
      if (!this.couponInfo) {
        return;
      }
      this.$emit("finishSelect", this.couponInfo);
    },
  },
  created() {
    var T;
    if (this.$store.state.language == 1) {
      T = "chinese";
    } else {
      T = "english";
    }
    this.apply = language.apply[T];
    this.useCoupon = language.useCoupon[T];
    this.quXiao = language.quXiao[T];
    this.SYMK = language.SYMK[T];
    this.TDCP = language.TDCP[T];
    this.BZCD = language.BZCD[T];
    this.BZSYSJFW = language.BZSYSJFW[T];
    this.HQXXSB = language.HQXXSB[T];
    this.GWCWKYHCW = language.GWCWKYHCW[T];
  },
  components: {
    CouponList,
  },
};
</script>
<style scoped>
.couponBox {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0.1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.5);
}
.coupon {
  width: 100%;
  max-height: 70%;
  border-top-left-radius: 0.12rem;
  border-top-right-radius: 0.12rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  background-color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.1rem;
  box-sizing: border-box;
}
.title {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #808080;
  background-color: #fff;
  position: absolute;
  top: 0;
  border-top-left-radius: 0.12rem;
  border-top-right-radius: 0.12rem;
}
.title p:nth-child(2) {
  color: #051b24;
}
.closeIcon {
  width: 0.15rem;
  height: 0.15rem;
  background: url("../../assets/icon/close.png") no-repeat center;
  background-size: cover;
}
.couponList {
  height: 3rem;
  overflow: scroll;
  padding: 0.16rem;
  background-color: #f2f3f5;
}
.finishBtn {
  width: 3.43rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  background-color: #e4022b;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
}
.itemBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 0.12rem;
  overflow: hidden;
  margin-bottom: 0.16rem;
}
.selectIcon {
  width: 0.26rem;
  height: 0.24rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  margin: 0 0.04rem;
}
.selectIcon.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.btnBox {
  width: 100%;
  padding-top: 0.16rem;
  background-color: #f2f3f5;
}
.noneData {
  width: 2rem;
}
.noneC {
  width: 100%;
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #b1b1b3;
}
</style>